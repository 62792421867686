import React, { lazy, Suspense, useContext } from 'react'
import { StateStore } from './Store'
import { get } from 'lodash'

const AddObjectDialogueWrapper = lazy(
  () => import('./CommandMenu/AddObjectDialogueWrapper'),
)
const AddValidationDialogue = lazy(
  () => import('./FormDialogs/AddValidationRuleDialogue'),
)
const EditValidationDialogue = lazy(
  () => import('./FormDialogs/EditValidationRuleDialogue'),
)
const EditFieldDialogue = lazy(() => import('./FormDialogs/EditFieldDialogue'))
const AddFieldDialogue = lazy(() => import('./FormDialogs/AddFieldDialogue'))
const AddApexClassDialogue = lazy(
  () => import('./FormDialogs/AddApexClassDialogue'),
)
const CreateObjectModal = lazy(() => import('./CsvUpload/CreateObjectModal'))
const AddRollupFieldDialogue = lazy(
  () => import('./FormDialogs/Rollups/AddRollupFieldDialogue'),
)
const ReplaceFieldDialogue = lazy(
  () => import('./FormDialogs/ReplaceFieldDialogue'),
)
const ReplacePicklistDialogue = lazy(
  () => import('./FormDialogs/ReplacePicklistDialogue'),
)
const UpdateProfileFieldAccessDialogue = lazy(
  () => import('./FormDialogs/UpdateProfileFieldAccessDialogue'),
)
const UpdateFieldDescriptionsDialogue = lazy(
  () => import('./FormDialogs/UpdateFieldDescriptions'),
)

export const MODAL_KEYS = [
  'commandMenu',
  'addField',
  'addObject',
  'editField',
  'addValidation',
  'editValidation',
  'addApexClass',
  'startFromNewObject',
  'rollupField',
  'replaceField',
  'replacePicklist',
  'updateProfileFieldAccess',
  'updateFieldDescriptions',
] as const

export type ModalKey = (typeof MODAL_KEYS)[number]

// Updated ModalMap using lazy-loaded components
const ModalMap: Partial<
  Record<ModalKey, React.LazyExoticComponent<(props: any) => JSX.Element>>
> = {
  addObject: AddObjectDialogueWrapper,
  addField: AddFieldDialogue,
  addValidation: AddValidationDialogue,
  editValidation: EditValidationDialogue,
  editField: EditFieldDialogue,
  addApexClass: AddApexClassDialogue,
  startFromNewObject: CreateObjectModal,
  rollupField: AddRollupFieldDialogue,
  replaceField: ReplaceFieldDialogue,
  replacePicklist: ReplacePicklistDialogue,
  updateProfileFieldAccess: UpdateProfileFieldAccessDialogue,
  updateFieldDescriptions: UpdateFieldDescriptionsDialogue,
}

const RootModal = () => {
  const { state } = useContext(StateStore)
  const currentOpenKey = get(state, 'modal.key')

  // Find the correct modal
  const CurrentModal = currentOpenKey
    ? ModalMap[currentOpenKey as ModalKey]
    : null

  return <Suspense>{CurrentModal ? <CurrentModal /> : null}</Suspense>
}

export default RootModal
